<template>
  <div>
    <el-dialog
      width="30%"
      title="添加收据"
      :visible.sync="addreceiptVisible"
      append-to-body
       @close="addreceiptVisibleClose"
       :before-close="addreceiptVisibleClose"
      custom-class="addreceipt-dialog"
      top="10%"
    >
      <div class="addreceipt-container">
        <div class="addreceiptItem">
            <span class="addreceiptTitle">记录人:</span> <el-input v-model="input" placeholder="请输入内容"></el-input>
        </div>
        <div class="addreceiptItem gridbigItem">
            <span class="addreceiptTitle">收据截图:</span>
            <el-upload
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              class="imgListBox"
            >
              <template #default>
                <i class="el-icon-plus"></i>
              </template>
              <template #file="{ file }">
                <div>
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleDownload(file)"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </template>
            </el-upload>
            <el-dialog v-model="imgdialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
        </div>
        <div class="addreceiptItem">
            <span class="addreceiptTitle">付款时间</span>
            <el-date-picker
              v-model="value1"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="1">取消</el-button>
        <el-button @click="1" class="resBtn">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
         input: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      dialogImageUrl: '',
      imgdialogVisible: false,
      disabled: false,
      value1: '',
    }
  },
  props: {
    addreceiptVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgdialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    addreceiptVisibleClose(){
    console.log('内置dialog被关闭')
    this.$emit('addreceiptVisibleClose')
    }
  }
}
</script>

<style lang="scss">
.addreceipt-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 400px;
    overflow: auto;
    padding: 50px;
    .addreceiptItem{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .addreceiptTitle{
            width: 100px;
        }
        .el-input{
            flex: 1;
        }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>