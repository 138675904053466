<template>
  <div>
    <el-dialog
      title="续费详细"
      :visible.sync="dialogVisible"
      @close="dialogVisibleClose"
      :before-close="dialogVisibleClose"
      append-to-body
      width="1300px"
      class="renewaldetails-dialog"
    >
      <div class="renewaldetails-container">
        <div class="renewaldetailsItem">
          <div class="renewaldetails-title">
            天灿人力资源有限公司
            <el-dropdown
              placement="bottom-end"
              trigger="click"
              @command="itemClick"
            >
              <span class="el-dropdown-link">
                <i
                  class="colorItem"
                  :style="{ backgroundColor: dropdownItem.color }"
                ></i
                >{{ dropdownItem.state
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in dropdownOption"
                  :key="index"
                  :command="{ item, index }"
                >
                  <i class="el-icon-check" v-show="index == currentIndex"></i>
                  <i
                    class="colorItem"
                    :style="{ backgroundColor: item.color }"
                  ></i>
                  {{ item.state }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="renewaldetails-GridBox">
            <!-- 公司编号 -->
            <div class="renewaldetails-gridItem">
              <span>公司编号:</span><span>{{ rowdata.company_number }}</span>
            </div>
            <!-- 联系人 -->
            <div class="renewaldetails-gridItem">
              <span>区域:</span><span>广西南宁</span>
            </div>

            <!-- 公司性质 -->
            <div class="renewaldetails-gridItem">
              <span>联系人:</span><span>{{ rowdata.initiator }}</span>
            </div>
            <!-- 手机号码 -->
            <div class="renewaldetails-gridItem">
              <span>手机号码:</span><span>{{ rowdata.phone_number }}</span>
            </div>
            <!-- 公司性质 -->
            <div class="renewaldetails-gridItem">
              <span>公司性质:</span><span>未知</span>
            </div>
            <!-- 付款时间 -->
            <div class="renewaldetails-gridItem">
              <span>付款时间:</span><span>{{ rowdata.payment_time }}</span>
            </div>
            <!-- 区域 -->
            <div class="renewaldetails-gridItem">
              <span>区域:</span><span></span>
            </div>
          </div>
        </div>
        <div class="renewaldetailsItem">
          <div class="customer-detailsItem padingBox">
            <el-tabs type="border-card">
              <el-tab-pane>
                <span slot="label" name="first"
                  ><i class="iconfont icon-jiaofeijilu"></i> 缴费收据</span
                >
                <paymentreceipttable-com
                  @editBtnClickfunc="editBtnClickfunc"
                ></paymentreceipttable-com>
              </el-tab-pane>
            </el-tabs>
            <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          
        </el-tabs> -->
          </div>
        </div>
      </div>

      <detailediting-com
        :biajiVisible="biajiVisible"
        @editdialogVisibleClose="editdialogVisibleClose"
      ></detailediting-com>
      <addreceipt-com
        :addreceiptVisible="addreceiptVisible"
        @addreceiptVisibleClose="addreceiptVisibleClose"
      ></addreceipt-com>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addreceiptVisible = true">添加收据</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import paymentreceipttableCom from './paymentreceipttableCom.vue'
import detaileditingCom from './detaileditingCom.vue'
import addreceiptCom from './addreceiptCom.vue'

export default {
  components: {
    paymentreceipttableCom, detaileditingCom, addreceiptCom
  },
  data() {
    return {

      biajiVisible: false,
      addreceiptVisible: false,
      currentIndex: 0,
      dropdownItem: { color: '#4ecbe3', state: '已确认' },
      dropdownOption: [
        { color: '#4ecbe3', state: '已确认' },
        { color: '#999999', state: '未确认' },
        { color: '#ff7079', state: '异常' },

      ],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowdata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    dialogVisibleClose() {
      this.$emit('dialogVisibleClose')
    },
    itemClick(item) {
      console.log(item)
      this.dropdownItem = item.item
      this.currentIndex = item.index
    },
    editBtnClickfunc(bl) {
      this.biajiVisible = bl
    },
    editdialogVisibleClose() {
      this.biajiVisible = false
    },
    addreceiptVisibleClose() {
      this.addreceiptVisible = false
    }
  }
}
</script>


<style lang="scss">
.renewaldetails-dialog {
  .el-dialog__header {
    background-color: $index-button-color;
    .el-dialog__title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
    .el-icon-close {
      color: #ffffff;
    }
  }
  .el-dialog__body {
    padding: 0;
    height: 800px;
    overflow: auto;
  }
}

.colorItem {
  display: inline-block !important;
  content: "" !important;
  height: 10px !important;
  width: 10px !important;
  background-color: $index-button-color;
  border-radius: 50%;
  margin-right: 10px;
}
.renewaldetails-container {
  height: 800px;
  overflow: hidden;
  .renewaldetailsItem:last-child {
    border-bottom: none;
  }
  .renewaldetailsItem {
    padding: 30px 20px;
    border-bottom: 2px solid $index-borderBottom-color;
    .renewaldetails-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: bold;
      color: #333333;

      span {
        margin-left: 30px;
        font-weight: 400;
        font-size: 16px;
      }
    }
    .renewaldetails-GridBox {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      .renewaldetails-gridItem {
        display: flex;
        font-size: 16px;
        span:first-child {
          font-size: 16px;
        }
        span:last-child {
          flex: 1;
          margin-left: 10px;
        }
      }
    }
    .el-tabs--border-card {
      border: none;
      box-shadow: none;
    }
    .el-tabs__header {
      border: none;
      background-color: #ffffff;
    }
    .el-tabs__content {
      padding: 0;
    }
    .is-active {
      border-radius: 6px 6px 0 0;
      background-color: $index-button-color !important;
      color: #ffffff !important;
    }
  }
}
</style>