<template>
  <div class="paymentreCordCom-container">
    <el-table :data="rowdata" style="width: 100%">
      <el-table-column label="序号" type="index" align="center" width="80">
      </el-table-column>
      <el-table-column prop="note_taker" label="业务类型" align="center">
      </el-table-column>
      <el-table-column prop="renewal_amount" label="续费金额" align="center">
      </el-table-column>
      <el-table-column prop="note_taker" label="付款类型" align="center">
      </el-table-column>
      <el-table-column label="收据截图" align="center" width="400">
        <template slot-scope="scope">
          <!-- 图片显示 -->
          <div class="flexBox">
            <div
              class="screenshotItem"
              v-for="(item, index) in scope.row.payment_screenshot"
              :key="index"
            >
              <img
                src="../../assets/shouju.png"
                alt=""
                height="60px"
                @click="screenshotClickFunc(item)"
              />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="payment_time" label="付款时间" align="center">
      </el-table-column>
      <el-table-column prop="note_taker" label="发起人" align="center">
      </el-table-column>
      <el-table-column prop="note_taker" label="状态" align="center">
      </el-table-column>
      <!-- 操作（固定） -->
      <el-table-column fixed="right" label="操作" align="center">
        <template slot-scope="scope">
          <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="errBtn btnItem"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="imgshowVisible"
      width="600px"
      append-to-body
      top="40vh"
      title="图片查看"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogImageUrl: '',
      imgshowVisible: false,
      /**
       * note_taker:记录人
       * payment_time:付款时间
       * payment_screenshot:缴费图片
       * renewal_amount:续费金额
       */
      rowdata: [
        {
          note_taker: 123456,
          payment_time: 123456,
          payment_screenshot: ['../../assets/shouju.png', '../../assets/shouju.png'],
          renewal_amount: '3500元'
        }
      ]
    }
  },
  methods: {
    handleClick(row) {
      console.log(row)
      this.$emit('editBtnClickfunc', true)
    },
    screenshotClickFunc(data) {
      console.log(data);
      this.imgshowVisible = true
    }
  },
};
</script>

<style lang="scss">
.paymentreCordCom-container {
  height: 400px;
  .el-table {
    padding: 0;
  }
  .el-table th {
    background-color: $index-button-color;
    color: #ffffff;
    font-size: 16px;
    .el-icon-arrow-down:before {
      content: "\e790";
      font-size: 16px;
      color: #ffffff;
    }
  }
  .el-table__row {
    .flexBox {
      display: flex;
      .screenshotItem {
        margin-left: 10px;
        flex: 1;
        img {
          height: 60px;
          width: 100%;
        }
      }
    }
    .btnItem {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
  }
}
</style>