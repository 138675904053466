<template>
  <!-- 续费管理 -->
  <div class="renewalmanagement-Container">
    <!-- 输入框 -->
    <div class="selectContainer">
      <!-- 用户名称或手机号 -->
      <el-input
        placeholder="客户(公司)名称"
        suffix-icon="el-icon-search"
        v-model="searchInfo.name"
        @keyup.enter.native="searchUser"
      >
      </el-input>
      <!-- 公司编号 -->
      <el-input
        placeholder="公司编号"
        suffix-icon="el-icon-search"
        v-model="searchInfo.number"
        @keyup.enter.native="searchUser"
      >
      </el-input>
      <!-- 联系人 -->
      <el-input
        placeholder="联系人"
        suffix-icon="el-icon-search"
        v-model="searchInfo.contacts"
        @keyup.enter.native="searchUser"
      >
      </el-input>
      <!-- 地址 -->
      <el-input
        placeholder="地址"
        suffix-icon="el-icon-search"
        v-model="searchInfo.address"
        @keyup.enter.native="searchUser"
      >
      </el-input>
      <!-- 所属客服 -->
      <el-select v-model="searchInfo.service_id" placeholder="请选择所属客服">
        <el-option
          v-for="item in customer_servicelist"
          :key="item.id"
          :label="item.real_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- 所属片区 -->
      <el-select v-model="searchInfo.area_id" placeholder="请选择所属片区">
        <el-option
          v-for="item in areaOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 公司性质 -->
      <el-select v-model="searchInfo.nature" placeholder="请选择公司性质">
        <el-option
          v-for="item in NatureList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 日期选择 -->
      <el-date-picker
        v-model="searchInfo.start_time"
        type="datetimerange"
        :picker-options="pickerOptions"
        start-placeholder="开始合作日期"
        end-placeholder="结束日期"
        align="center"
      >
      </el-date-picker>
      <!-- 合作状态 -->
      <el-select
        v-model="searchInfo.cooperate_status"
        placeholder="请选择合作状态"
      >
        <el-option
          v-for="item in cooperate_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 合同状态 -->
      <!-- <el-select
        v-model="searchInfo.cooperate_statu"
        placeholder="请选择合同状态"
      >
        <el-option
          v-for="item in cooperate_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
    </div>
    <!-- 增删改查 -->
    <div class="managementBox">
      <el-button class="select">查询</el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <!-- <div class="addBtnBox">
      <el-button
        icon="el-icon-circle-plus-outline"
        class="addBtn"
        @click="addUserFunc"
        >新增</el-button
      >
    </div> -->
    <div class="table-container">
      <!-- <table-com></table-com> -->
      <renewaltable-com></renewaltable-com>
    </div>
    <pagination-com></pagination-com>
    <!-- <div class="pagination-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="100"
        layout="prev, pager, next, jumper"
        :total="1000"
      >
      </el-pagination>
    </div> -->
  </div>
</template>




<script>
// import tableCom from '../components/tableCom';
import renewaltableCom from '../components/renewaltable/renewaltableCom.vue'
import paginationCom from '../components/paginationCom.vue'
export default {
  name: 'businessManagement',
  components: {
    paginationCom, renewaltableCom
  },
  data() {
    return {
      addUserComShow: false,
      searchInfo: {
        limit: 20,
        name: "",
        number: "",
        contacts: "",
        address: "",
        start_time: '',
        end_time: '',
        nature: '',
        area_id: "",
        cooperate_status: "",//合作状态
        service_id: '',
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      customer_servicelist: [],
      areaOptions: [
        {
          value: "1",
          label: "南宁总部"
        },
        {
          value: "2",
          label: "南宁高新"
        },
        {
          value: "3",
          label: "南宁青秀"
        },
        {
          value: "4",
          label: "南宁江南"
        },
        {
          value: "5",
          label: "玉林"
        },
        {
          value: "6",
          label: "柳州"
        },
        {
          value: "7",
          label: "桂林"
        },
        {
          value: "8",
          label: "百色"
        },
        {
          value: "9",
          label: "来宾"
        },
        {
          value: "10",
          label: "梧州"
        },
        {
          value: "11",
          label: "钦州"
        }
      ],
      cooperate_options: [
        {
          value: "1",
          label: "正常"
        },
        {
          value: "2",
          label: "欠费"
        }, {
          value: "3",
          label: "停报"
        }, {
          value: "4",
          label: "迁走"
        }, {
          value: "5",
          label: "注销"
        }, {
          value: "6",
          label: "转让"
        }
      ],
      NatureList: [
        {
          value: "1",
          label: "小规模"
        },
        {
          value: "2",
          label: "一般纳税人"
        },
        {
          value: "3",
          label: "小规模个体户"
        },
        {
          value: "4",
          label: "一般纳税人个体户"
        }
      ],
      currentPage3: 1,
      // 日期选择器配置
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: '',
      value: ''
    }
  },
  methods: {
    addUserFunc() {
      this.addUserComShow = true;
    },
    reset() {
      this.searchInfo.name = '',
        this.searchInfo.number = '',
        this.searchInfo.contacts = '',
        this.searchInfo.address = '',
        this.searchInfo.start_time = '',
        this.searchInfo.end_time = '',
        this.searchInfo.nature = '',
        this.searchInfo.area_id = '',
        this.searchInfo.cooperate_status = '',
        this.searchInfo.service_id = ''
    },
    dialogVisibleClose(bl) {
      this.addUserComShow = bl;
    },
    handleCurrentChange() {

    },
    handleSizeChange() {

    }
  }
}
</script>


<style lang='scss'>
.renewalmanagement-Container {
  box-sizing: border-box;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;

  .selectContainer {
    display: grid;
    grid-row-gap: 20px;
    // grid-column-gap: 25px;
    grid-template-columns: repeat(auto-fill, 400px);
    justify-content: space-between;
    .el-input__inner {
      display: block;
      width: 400px !important;
    }
    .el-date-editor--datetimerange {
      // grid-column-start:项目的起始列，
      // grid-column-start: 2;

      // grid-column-end:项目结束列，
      // grid-column-end: 4;

      // 元素的左边框距离右边框跨越2个网格
      grid-column-end: span 2;

      width: 100% !important;
      line-height: 0;
      .el-range-separator {
        color: $index-hui-color;
      }
      .el-range-input {
        width: 40%;
      }
    }
  }
  .managementBox {
    margin-top: 10px;
    text-align: left;
    .el-button {
      width: 108px;
    }
    .select {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
  }
  .addBtnBox {
    margin-top: 10px;
    text-align: left;
    .addBtn {
      width: 108px;
      background-color: #8674eb;
      color: #ffffff;
    }
  }
  .table-container {
    height: 900px;
    overflow: hidden;
    margin-top: 30px;
  }
  //   .pagination-container {
  //     margin-top: 30px;
  //     height: 40px;
  //     .el-pagination {
  //       height: 100%;
  //       box-sizing: border-box;
  //       color: #666666;
  //       .el-pager li {
  //         padding: 0 25px;
  //         font-size: 16px !important;
  //         height: 100%;
  //         line-height: 40px;
  //       }
  //       .el-pager li.active {
  //         color: #409eff;
  //       }
  //       .btn-prev,
  //       .btn-next {
  //         padding: 0 30px;
  //         height: 40px;
  //         text-align: center;
  //       }
  //       .btn-prev {
  //         border-radius: 10px 0 0 10px;
  //       }
  //       .btn-next {
  //         border-radius: 0px 10px 10px 0px;
  //       }
  //       .el-pagination__jump {
  //         .el-input {
  //           margin: 0 20px;
  //           font-size: 16px;
  //         }
  //       }
  //       button,
  //       span:not([class*="suffix"]) {
  //         height: 40px;
  //         line-height: 40px;
  //         font-size: 16px !important;
  //       }
  //     }
  //     .el-icon-arrow-left:before {
  //       content: "上一页";
  //       font-size: 16px;
  //     }
  //     .el-icon-arrow-right:before {
  //       content: "下一页";
  //       font-size: 16px;
  //     }
  //   }
}
</style>