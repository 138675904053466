<template>
  <div class="renewaltableCom-container">
    <el-table :data="tableData" style="width: 100%; height: 100%" stripe>
      <!-- 序号 -->
      <el-table-column
        fixed
        label="序号"
        width="100"
        align="center"
        type="index"
      >
      </el-table-column>

      <!-- 公司名称 -->
      <el-table-column
        prop="corporate_name"
        label="公司名称"
        align="center"
        fixed
      >
      </el-table-column>
      <!-- 公司编号 -->
      <el-table-column prop="company_number" label="公司编号" align="center">
      </el-table-column>
      <!-- 联系电话 -->
      <el-table-column prop="phone_number" label="联系电话" align="center">
      </el-table-column>
      <!-- 本期套餐 -->
      <el-table-column
        prop="current_package"
        label="本期套餐"
        align="center"
        :filters="currentpackageList"
        :filter-method="filtercurrentpackage"
        filter-placement="bottom-end"
      >
        <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
        <template slot-scope="scope">
          {{ scope.row.current_package }}
        </template>
      </el-table-column>
      <!-- 续费金额 -->
      <el-table-column prop="renewal_amount" label="续费金额" align="center">
      </el-table-column>
      <!-- 发起人 -->
      <el-table-column prop="initiator" label="发起人" align="center">
      </el-table-column>
      <!-- 付款类型 -->
      <el-table-column
        prop="paymentType"
        label="付款类型"
        align="center"
        :filters="paymenttypeList"
        :filter-method="filterpaymenttype"
        filter-placement="bottom-end"
      >
        <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
        <template slot-scope="scope">
          {{ scope.row.paymentType }}
        </template>
      </el-table-column>
      <!-- 付款时间 -->
      <el-table-column prop="payment_time" label="付款时间" align="center">
      </el-table-column>
      <!-- 当前状态 -->
      <el-table-column
        prop="state"
        label="当前状态"
        align="center"
        :filters="stateList"
        :filter-method="filterstateList"
        filter-placement="bottom-end"
      >
        <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
        <template slot-scope="scope">
          {{ scope.row.state }}
        </template>
      </el-table-column>
      <!-- 操作（固定） -->
      <el-table-column fixed="right" label="操作" width="280" >
        <template slot-scope="scope">
          <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="errBtn btnItem"
            v-if="scope.row.state == '待确认'"
            >驳回</el-button
          >
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="priBtn btnItem"
            v-if="scope.row.state == '待确认'"
            >确认</el-button
          >

          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seeBtn btnItem" 
            >详情</el-button
          >

          <!-- <el-button type="button" class="detailsBtn">编辑</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <renewal-details
      :dialogVisible="dialogVisible"
      :rowdata="rowdata"
      @dialogVisibleClose="dialogVisibleClose"
    ></renewal-details>
  </div>
</template>

<script>
import renewalDetails from './renewaldetailsCom'


export default {
  components: {
    renewalDetails,
  },
  
  data() {
    return {
      // 序号的排序
      index: 1,
      // 详情显示框显示
      dialogVisible: false,
      addUserComShow: false,
      rowdata: {},
      tableData: [
        /**
         * ID:序号，
         * company_number:公司编号，
         * corporate_name:公司名称，
         * current_package:本期套餐
         * renewal_amount:续费金额
         * initiator:发起人
         * paymentType:付款类型
         * payment_time:付款时间
         * state:状态
         */
        {
          id: 1,
          company_number: '13c2346c-fe1f-123as45-sad235456310-sad4512354-1234510',
          corporate_name: '广西天灿会计服务有限公司',
          current_package: '套餐一',
          renewal_amount: '3500元',
          initiator: '天灿灿',
          paymentType: '微信',
          payment_time: '2021-2-21 18:00:00',
          phone_number: 18307761111,
          state: '已确认'
        },
        {
          id: 2,
          company_number: '13c2346c-fe1f-123as45-sad235456310-sad4512354-1234510',
          corporate_name: '广西天灿会计服务有限公司',
          current_package: '套餐二',
          renewal_amount: '3500元',
          initiator: '天灿灿',
          paymentType: '微信',
          payment_time: '2021-2-21 18:00:00',
          phone_number: 18307761111,
          state: '待确认'
        },
        {
          id: 3,
          company_number: '13c2346c-fe1f-123as45-sad235456310-sad4512354-1234510',
          corporate_name: '广西天灿会计服务有限公司',
          current_package: '套餐三',
          renewal_amount: '3500元',
          initiator: '天灿灿',
          paymentType: '微信',
          payment_time: '2021-2-21 18:00:00',
          phone_number: 18307761111,
          state: '异常'
        },
      ],
      stateList: [
        { text: '已确认', value: '已确认' },
        { text: '待确认', value: '待确认' },
        { text: '异常', value: '异常' },
      ],
      currentpackageList: [
        { text: '套餐一', value: '套餐一' },
        { text: '套餐二', value: '套餐二' },
        { text: '套餐三', value: '套餐三' },
        { text: '套餐四', value: '套餐四' },
      ],
      paymenttypeList: [
        { text: '微信', value: '微信' },
        { text: '支付宝', value: '支付宝' },
        { text: '现金', value: '现金' },
        { text: '工商', value: '工商' },
        { text: '农行', value: '农行' },
        { text: '建行', value: '建行' },
        { text: '对公', value: '对公' },
        { text: '农行收款码', value: '农行收款码' },
      ]
    }
  },
  methods: {
    handleClick(row) {
      this.dialogVisible = true;
      this.rowdata = row;
      console.log(row);
    },
    // 选择
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('date');
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    dialogVisibleClose() {
      console.log('子组件调用父组件方法');
      this.dialogVisible = false
    },

    // 性质选择
    filterstateList(value, row) {
      return row.state === value;
    },
    // 套餐选择
    filtercurrentpackage(value, row) {
      return row.current_package === value;
    },
    //  付款类型
    filterpaymenttype(value, row) {
      // 判断 当前的行的paymentType是不是等于选定的值  类似于数组的filter方法
      return row.paymentType === value
    }
  },
}
</script>

<style lang='scss'>
.renewaltableCom-container {
  .el-button {
    color: #ffffff;
  }
  .el-table th {
    background-color: $index-button-color;
    color: #ffffff;
    font-size: 16px;
    .el-icon-arrow-down:before {
      content: "\e790";
      font-size: 16px;
      color: #ffffff;
    }
  }
  .el-table tr {
    background-color: #ffffff;
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 3px;
  }
  .el-table {
    .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 15px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: 0px 1px 3px #f9f9f9e7 inset; /*滚动条的背景区域的内阴影*/
      border-radius: 10px; /*滚动条的背景区域的圆角*/
      background-color: #f9f9f9; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0px 1px 3px $index-button-color inset; /*滚动条的内阴影*/
      border-radius: 10px; /*滚动条的圆角*/
      background-color: $index-button-color; /*滚动条的背景颜色*/
    }
  }
}

.table-container .renewaltableCom-container .btnItem {
  width: 80px;
}
.table-container .renewaltableCom-container .seeBtn {
  color: $index-button-color;
  border: 1px solid $index-button-color;
  background-color: #ffffff;
}
.table-container .renewaltableCom-container .errBtn {
  background-color: $index-buttonerr-color;
}
 .table-container .renewaltableCom-container .priBtn {
  background-color: $index-button-color;
}
 .table-container .renewaltableCom-container .seeBtn:hover {
  background-color: #000000;
}
.detailsBtn {
  width: 100px;
  background-color: $index-buttonPor-color;
}
.detailsBtn:hover {
  background-color: #000000;
}
</style>